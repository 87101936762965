import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Link,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../../assets/logo.svg';
import { Avatar } from '@ui/components/Avatar';
import { useNavigationContext } from './context';

const NAV_MAX_WIDTH = 200;

type RootProps = {
  children?: React.ReactNode;
};

export function Root({ children }: RootProps) {
  const { isOpen, setIsOpen } = useNavigationContext();

  return (
    <Box
      as="nav"
      minWidth={isOpen ? NAV_MAX_WIDTH : '80px'}
      height="100vh"
      borderRight="1px solid"
      borderRightColor="mw.black"
      bgColor="mw.black"
      textColor="white"
      position="sticky"
      zIndex={1}
      top={0}
      left={0}
      py={4}
    >
      <Flex direction="column" height="100%">
        <Flex
          as={Link}
          href="/"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="50px"
        >
          <Image
            src={logo}
            alt="MW Components Logo"
            title="MW Components Logo"
            maxWidth="30px"
          />
        </Flex>

        <VStack spacing="0px" alignItems="flex-start" mt={2} mx={4}>
          {children}
        </VStack>

        <Button
          variant="ghost"
          color="white"
          width="20px"
          p={0}
          height="40px"
          minWidth="unset"
          onClick={() => setIsOpen(!isOpen)}
          backgroundColor="mw.darkBlue"
          _hover={{
            bg: 'mw.darkBlue',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
          }}
          overflow="hidden"
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          left={isOpen ? `${NAV_MAX_WIDTH - 12}px` : '68px'}
        >
          <FontAwesomeIcon
            fontSize={14}
            icon={isOpen ? faChevronLeft : faChevronRight}
            title={isOpen ? 'Collapse Menu' : 'Expand Menu'}
          />
        </Button>

        <Spacer />

        <Center height="50px">
          <Avatar />
        </Center>
      </Flex>
    </Box>
  );
}
