import { Card, Center, Link, Text } from '@chakra-ui/react';
import type { FC } from 'react';

export const NotFound: FC = () => {
  return (
    <Center height="100vh">
      <Card p={10} textAlign="center" justifyContent="center">
        <Text fontSize={36} mb={4}>
          404
        </Text>
        <Text mb={8}>Couldn't find what you're looking for</Text>
        <Link href="/" fontWeight="bold">
          Back to Home
        </Link>
      </Card>
    </Center>
  );
};

export default NotFound;
